import * as React from 'react'

import '../reset.css'
import '../variables.css'
import '../index.css'

interface Props {
  children: React.ReactNode
}

const BareLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  )
}

export default BareLayout
