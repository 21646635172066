import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import axios from 'axios'
import { Link } from 'gatsby'

import logo from '../images/logo_white.png'

import SEO from '../components/Seo'
import { device } from '../components/Styles'
import EarlyAccessForm from '../components/EarlyAccessForm'
import BareLayout from '../components/BareLayout'

const Row = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
  }
`
const Left = styled.div`
  padding: 4rem;

  @media ${device.laptop} {
  }
`
const Right = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  color: #000;
  padding: 0 4rem 0 4rem;

  @media ${device.laptop} {
  }
`
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-gray-600);
`
const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;

  > li {
    margin: 0 0 1rem 0;
  }
`
const Subtitle = styled.p`
  margin-bottom: 1rem;
`
const Icon = styled.span`
  color: var(--color-green-400);
  margin-right: 0.5rem;
`
const Logo = styled.img`
  margin-bottom: 4rem;
  height: 64px;
`
const Early = styled.div`
  margin: 12rem auto;

  h1 {
    font-size: 2rem;
    color: #000;
    font-weight: 700;
  }
`
const Subscribed = styled.div`
  background-color: var(--color-green-600);
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  margin-top: 2rem;
`

const IndexPage = (): React.ReactElement => {
  const [subscribed, setSubcribed] = React.useState(false)

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      const response = await axios.post('/.netlify/functions/subscribe', {
        email
      })
      setSubcribed(true)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <BareLayout>
      <SEO title="Get early access" />

      <Row>
        <Left>
          <Link to={'/'}>
            <Logo src={logo} />
          </Link>
          <Title>Backfill beta release</Title>
          <Subtitle>Free Tier includes:</Subtitle>
          <FeaturesList>
            <li>
              <Icon>
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
              </Icon>
              Scripts can run upto 1hr
            </li>
            <li>
              <Icon>
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
              </Icon>
              Upto 3 team members
            </li>
            <li>
              <Icon>
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
              </Icon>
              Slack and email notifications
            </li>
          </FeaturesList>
        </Left>
        <Right>
          <Early>
            <h1>Level up your internal tooling</h1>
            <p>Start with a free basic tier.</p>
            <br />
            {subscribed ? (
              <Subscribed>
                <p>You have been added to the early access list!</p>
              </Subscribed>
            ) : (
              <EarlyAccessForm onSubmit={handleSubmit} />
            )}
          </Early>
        </Right>
      </Row>
    </BareLayout>
  )
}

export default IndexPage
