import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Field from './Field'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Button = styled.button`
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background-color: rgb(72 128 237);
  color: #fff;
  font-weight: 600;
  border-radius: 3rem;
  border: 0;
  cursor: pointer;
`
const Action = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required()
})

const defaultValues = {
  email: ''
}

type FormValues = typeof defaultValues

interface Props {
  onSubmit: (values: FormValues) => void
}

const EarlyAccessForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Formik validationSchema={validationSchema} initialValues={defaultValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form className="space-y-2">
          <div>
            <Field name="email" type="text" className="w-full" placeholder="Enter your email address" />
          </div>
          <Action>
            <Button type="submit">Request early access</Button>
            <div>
              Have an invite code? <a href="https://app.backfill.dev/login">Log in</a>
            </div>
          </Action>
        </Form>
      )}
    </Formik>
  )
}

export default EarlyAccessForm
